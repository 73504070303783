var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ModalWrapper", [
    _c("div", { staticClass: "modal", style: `height: ${_vm.currentHeight}` }, [
      _c(
        "div",
        [
          _c("div", { staticClass: "modal__title" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _vm._l(_vm.currentList, function (item) {
            return _c(
              "div",
              {
                key: item.id,
                class: [
                  "modal__item",
                  { active: item.id === _vm.currentActive },
                ],
                on: {
                  click: function ($event) {
                    _vm.currentActive = item.id
                  },
                },
              },
              [_vm._v(" " + _vm._s(item.value) + " ")]
            )
          }),
        ],
        2
      ),
      _c(
        "div",
        [
          _c("el-pagination", {
            staticClass: "custom-pagination",
            attrs: {
              background: "",
              "hide-on-single-page": true,
              "page-count": _vm.totalPages,
              "pager-count": 5,
              "current-page": _vm.currentPage,
              layout: "prev, pager, next",
            },
            on: {
              "update:currentPage": function ($event) {
                _vm.currentPage = $event
              },
              "update:current-page": function ($event) {
                _vm.currentPage = $event
              },
              "current-change": _vm.changePage,
            },
          }),
          _c(
            "div",
            { staticClass: "modal__buttons" },
            [
              _c("MainButton", {
                attrs: {
                  title: "Отменить",
                  type: "secondary",
                  size: "small",
                  width: "half",
                },
                on: {
                  "button-clicked": function ($event) {
                    return _vm.$emit("cancel")
                  },
                },
              }),
              _c("MainButton", {
                attrs: {
                  title: "Выбрать",
                  type: "primary",
                  size: "small",
                  width: "half",
                  disabled: !Boolean(_vm.currentActive),
                },
                on: { "button-clicked": _vm.selectItem },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }