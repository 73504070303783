<template>
  <ModalWrapper>
    <div class="modal" :style="`height: ${currentHeight}`">
      <div>
        <div class="modal__title">{{ title }}</div>
        <div
          v-for="item in currentList"
          :key="item.id"
          :class="['modal__item', { active: item.id === currentActive }]"
          @click="currentActive = item.id"
        >
          {{ item.value }}
        </div>
      </div>
      <div>
        <el-pagination
          background
          :hide-on-single-page="true"
          :page-count="totalPages"
          :pager-count="5"
          :current-page.sync="currentPage"
          layout="prev, pager, next"
          class="custom-pagination"
          @current-change="changePage"
        />
        <div class="modal__buttons">
          <MainButton
            title="Отменить"
            type="secondary"
            size="small"
            width="half"
            @button-clicked="$emit('cancel')"
          />
          <MainButton
            title="Выбрать"
            type="primary"
            size="small"
            width="half"
            :disabled="!Boolean(currentActive)"
            @button-clicked="selectItem"
          />
        </div>
      </div>
    </div>
  </ModalWrapper>
</template>

<script>
import MainButton from '@/views/terminalUI/components/UI/buttons/MainButton'
import ModalWrapper from '@/views/terminalUI/components/modals/ModalWrapper'

// ! количество элементов списка на странице
const PER_PAGE = 5

export default {
  name: 'SelectOptionsModal',
  components: { ModalWrapper, MainButton },
  props: {
    title: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => [],
    },
    active: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentActive: null,
      currentPage: 1,
    }
  },
  computed: {
    currentList() {
      return this.list.slice((this.currentPage - 1) * PER_PAGE, this.currentPage * PER_PAGE)
    },
    totalPages() {
      return Math.ceil(this.list.length / PER_PAGE)
    },
    // чтобы модалка не скакала по размеру на последней неполной странице списка,
    // в случае пагинации фиксируем ее высоту
    currentHeight() {
      return this.totalPages > 1 ? `${280 + 62 * PER_PAGE}px` : 'auto'
    },
  },

  mounted() {
    this.currentActive = this.active
  },
  methods: {
    selectItem() {
      if (this.currentActive) {
        this.$emit('accept', this.currentActive)
      }
    },
    changePage(page) {
      this.currentPage = page
    },
  },
}
</script>

<style lang="sass" scoped>
@import "@/assets/styles/terminalUI/custom-pagination"
.modal
  display: flex
  flex-direction: column
  justify-content: space-between
  width: 800px
  padding: 24px 0
  box-shadow: 0 0 20px rgba(132, 132, 132, 0.2)
  border-radius: 30px
  background: $color-white
  &__title
    margin-left: 24px
    margin-bottom: 24px
    font-family: $fontPrimary
    font-size: 32px
    font-weight: 500
    line-height: 40px
  &__item
    height: 62px
    padding: 0 24px
    font-family: $fontPrimary
    font-size: 24px
    line-height: 62px
    font-weight: 500
    color: $secondaryBlack
    cursor: pointer
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    &.active
      background: #EBF8F9
      color: $special
  &__buttons
    display: flex
    gap: 24px
    margin: 20px 24px 0
</style>
